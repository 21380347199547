import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Manasik extends BaseModel {
    fields =  {
      'manasik_date': {
        type: 'date',
        label: 'Tanggal Manasik',
        rules: 'required'
      },
      'package_id': {
        type: 'hidden',
        rules: 'required'
      },
      'manasik_start_time': {
        type: 'time',
        label: 'Jam Manasik',
        rules: 'required'
      },
      'manasik_end_time': {
        type: 'time',
        label: 'Selesai Manasik',
        rules: 'required'
      },
      'location_info': {
        type: 'textarea',
        label: 'Info Lokasi',
        rules: 'required'
      },
      'location_map': { 
        type: 'text',
        label: 'Map',
      },
    }

    find (id, params = {}) {
      return new Promise((resolve, reject) => {
        const url = new URL(this.endpoint + '?package_id=' + id)
        const urlParams = new URLSearchParams(url.search)
        Object.keys(params).forEach(key => {
          if (typeof params[key] === 'string' || typeof params[key] === 'number') {
            urlParams.append(key, params[key])
          }
        })
        axios.get(this.endpoint + '?package_id=' + id).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }

    endpoint = process.env.VUE_APP_API_URL + 'manasik-infos'
    // endpointManasikDetail = process.env.VUE_APP_API_URL + 'manasik-infos?pack='
}