<template>
  <div>
    <div class="row d-flex justify-content-end mr-1 mb-3">
      <b-btn
        variant="warning"
        href="#/master-data/Paket"
        class="ml-auto text-white"
        style="width: 7em;"
        >Kembali</b-btn
      >
      <b-btn
        variant="success"
        class="ml-2"
        style="width: 7em;"
        @click="copyPaket"
        >Copy</b-btn
      >
      <b-btn
        variant="primary"
        :href="'#/master-data/Paket/' + $route.params.id + '/edit'"
        class="ml-2"
        style="width: 7em;"
        >Edit</b-btn
      >
    </div>
    <b-card header-tag="header" class="">
      <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{ $route.name }}</h5>
          </b-col>
          <b-col cols="2">
            <div class="d-flex"></div>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <template v-for="(m, index) in model">
          <b-col
            :key="index"
            cols="4"
            v-if="
              (typeof m.disabled === 'undefined' || !m.disabled) &&
                m.label != 'Deskripsi' &&
                m.type !== 'hidden'
            "
          >
            <detail-group-input
              :key="index"
              :type="m.type"
              :label="typeof m.label === 'string' ? m.label : null"
              :value="m.value"
              :id="index"
            >
              <template slot="content_logo" slot-scope="defaultSlotScope">
                <img :src="defaultSlotScope.colData" width="200" />
              </template>
              <template slot="content_package_category_id">
                <span>{{ category }}</span>
              </template>
              <template slot="content_regency_id">
                <span>{{ city }}</span>
              </template>
              <template
                slot="content_description"
                slot-scope="defaultSlotScope"
              >
                <div class="position-relative">
                  <!-- <div class="heading-color">{{formatCurrency(defaultSlotScope.colData) }}</div> -->
                </div>
              </template>
              <template slot="content_price" slot-scope="defaultSlotScope">
                <span class="heading-color">{{
                  formatCurrency(defaultSlotScope.colData)
                }}</span>
              </template>
              <template slot="content_min_dp" slot-scope="defaultSlotScope">
                <span class="heading-color">{{
                  formatCurrency(defaultSlotScope.colData)
                }}</span>
              </template>
              <template
                slot="content_normal_price"
                slot-scope="defaultSlotScope"
              >
                <span class="heading-color">{{
                  formatCurrency(defaultSlotScope.colData)
                }}</span>
              </template>
              <template slot="content_is_active" slot-scope="defaultSlotScope">
                <span
                  :class="[
                    'badge',
                    defaultSlotScope.colData == 1
                      ? 'badge-v1-success'
                      : 'badge-v1-danger',
                  ]"
                >
                  {{ defaultSlotScope.colData == 1 ? "Aktif" : "Tidak Aktif" }}
                </span>
              </template>
            </detail-group-input>
          </b-col>
        </template>
      </b-row>
    </b-card>
    <div class="mt-3 mb-5 deep-detail">
      <b-card no-body class="card-custom">
        <b-tabs card>
          <b-tab title="Deskripsi" active>
            <div @click="() => (showDesc = !showDesc)">
              <div
                v-html="
                  description.value + ' <p><em><b>read less...</b></em></p>'
                "
                v-if="showDesc"
              ></div>
              <div
                v-html="
                  description
                    ? description.value.substring(0, 100) +
                      ' <p><em><b>read more...</b></em></p>'
                    : ''
                "
                v-else
              ></div>
            </div>
          </b-tab>
          <b-tab title="Perjalanan" active>
            <!-- <b-row class="d-flex justify-content-end">
             
            </b-row> -->
            <!-- <b-table sticky-header head-variant="light" :fields="table.perjalanan.fields"
              :items="table.perjalanan.items">
             
             
              <template #cell(action)="data">
                <b-button @click="deleteItineraries(data.item.id)" class="bg-danger text-white">
                  <b-icon class="text-white" icon="trash-fill" font-scale="0.9"></b-icon>
                </b-button>
              </template>

            </b-table> -->

            <!-- <b-button @click="deleteItineraries(data.item.id)" class="bg-danger text-white">
                <b-icon class="text-white" icon="trash-fill" font-scale="0.9"></b-icon>
            </b-button> -->
            <b-btn
              variant="primary"
              v-b-modal.perjalanan
              style="width: 7em;"
              class="mb-4 mr-3 float-right"
              v-if="this.table.perjalanan.items.length == 0"
              >Tambah</b-btn
            >

            <b-button
              v-b-modal.perjalanan
              variant="primary"
              class="mb-4 mr-3 float-right"
              v-else
            >
              Edit
            </b-button>

            <div
              v-html="
                this.table.perjalanan.items.length > 0
                  ? this.table.perjalanan.items[0].description
                  : ''
              "
              style="margin-top: 4em;margin-left: 1em;"
            ></div>
          </b-tab>
          <b-tab title="Foto">
            <b-row class="d-flex justify-content-end">
              <b-button
                v-b-modal.foto
                variant="primary"
                class="mb-4 mr-3 float-right"
              >
                Tambah
              </b-button>
            </b-row>
            <b-table
              sticky-header
              head-variant="light"
              :fields="table.foto.fields"
              :items="table.foto.items"
            >
              <template #cell(path)="data">
                <img :src="data.item.path._medium" width="100" alt="" />
              </template>
              <template #cell(action)="data">
                <b-button
                  @click="deleteFoto(data.item.id)"
                  class="bg-danger text-white"
                >
                  <b-icon icon="trash-fill" font-scale="0.9"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Hotel">
            <b-row class="d-flex justify-content-end">
              <b-button
                v-b-modal.hotel
                variant="primary"
                class="mb-4 mr-3 float-right"
              >
                Tambah
              </b-button>
            </b-row>
            <b-table
              sticky-header
              head-variant="light"
              :fields="table.hotel.fields"
              :items="table.hotel.items"
            >
              <template #cell(description)="data">
                {{ data.length > 0 ? data : "-" }}
              </template>
              <template #cell(is_active)="data">
                <p
                  :class="[
                    data.item.is_active == 1
                      ? 'badge bg-success text-white'
                      : 'badge bg-danger text-white',
                  ]"
                >
                  {{ data.item.is_active == 1 ? "Active" : "Not active" }}
                </p>
              </template>
              <template #cell(action)="data">
                <b-button
                  @click="deleteHotel(data.item.id)"
                  class="bg-danger text-white"
                >
                  <b-icon icon="trash-fill" font-scale="0.9"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Bandara">
            <div class="d-flex justify-content-end">
              <b-button v-b-modal.airport variant="primary my-3" deep-hidden
                >Tambah</b-button
              >
            </div>
            <div id="paginated-table">
              <el-table
                stripe
                :data="data.airport"
                border
                style="width: 100%;"
                empty-text="Data tidak ditemukan"
              >
                <el-table-column type="index" label="No" align="center" fixed />
                <el-table-column
                  prop="name"
                  align="center"
                  label="Nama Bandara"
                />
                <el-table-column prop="regency" label="Kota" align="center">
                  <template #default="{ row }">
                    {{ row.regency.name }}
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="Harga" align="right">
                  <template #default="{ row }">
                    <span>{{ formatCurrency(row.price) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="is_active" label="Status" align="center">
                  <template #default="{ row }">
                    <span
                      :class="[
                        'badge',
                        row.is_active == 1
                          ? 'badge-v1-success'
                          : 'badge-v1-danger',
                      ]"
                    >
                      {{ row.is_active == 1 ? "Aktif" : "Tidak Aktif" }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Aksi" width="100" align="center">
                  <template #default="{ row }">
                    <b-button variant="danger" @click="deleteAirport(row.id)">
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </b-tab>
          <b-tab title="Pesawat">
            <b-row class="d-flex justify-content-end">
              <b-button
                v-b-modal.airline
                variant="primary"
                class="mb-4 mr-3 float-right"
                >Tambah</b-button
              >
            </b-row>
            <b-table
              sticky-header
              head-variant="light"
              :fields="table.pesawat.fields"
              :items="table.pesawat.items"
            >
              <template #cell(logo)="data">
                <img
                  :src="'https://api.alshaftour.com/storage/' + data.item.logo"
                  width="100"
                />
              </template>
              <template #cell(is_active)="data">
                <p
                  :class="[
                    data.item.is_active == 1
                      ? 'badge bg-success text-white '
                      : 'badge bg-danger text-white',
                  ]"
                >
                  {{ data.item.is_active == 1 ? "Active" : "Not active" }}
                </p>
              </template>
              <template #cell(action)="data">
                <b-button
                  @click="deleteAirline(data.item.id)"
                  class="bg-danger text-white"
                >
                  <b-icon icon="trash-fill" font-scale="0.9"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Fasilitas">
            <b-row class="d-flex justify-content-end">
              <b-button
                v-b-modal.facility
                variant="primary"
                class="mb-4 mr-3 float-right"
                >Tambah</b-button
              >
            </b-row>
            <b-table
              sticky-header
              head-variant="light"
              :fields="table.fasilitas.fields"
              :items="table.fasilitas.items"
            >
              <template #cell(logo)="data">
                <img
                  :src="'https://api.alshaftour.com/storage/' + data.item.logo"
                  width="100"
                />
              </template>
              <template #cell(is_active)="data">
                <p
                  :class="[
                    data.item.is_active == 1
                      ? 'badge bg-success text-white'
                      : 'badge bg-danger text-white',
                  ]"
                >
                  {{ data.item.is_active == 1 ? "Active" : "Not active" }}
                </p>
              </template>
              <template #cell(action)="data">
                <b-button
                  @click="deleteFacility(data.item.id)"
                  class="bg-danger text-white"
                >
                  <b-icon icon="trash-fill" font-scale="0.9"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Addon">
            <b-row class="d-flex justify-content-end">
              <b-button
                v-b-modal.addon
                variant="primary"
                class="mb-4 mr-3 float-right"
                >Tambah</b-button
              >
            </b-row>
            <b-table
              sticky-header
              head-variant="light"
              :fields="table.addon.fields"
              :items="table.addon.items"
            >
              <template #cell(description)="data">
                {{
                  data.item.description == null ? "-" : data.item.description
                }}
              </template>
              <!-- <template #cell(price)="data">
                {{ parseInt(data.item.price).toLocateString('id-ID') }}
              </template> -->
              <template #cell(price)="data">
                <span class="heading-color">{{
                  formatCurrency(data.item.price)
                }}</span>
              </template>
              <template #cell(is_active)="data">
                <p
                  :class="[
                    data.item.is_active == 1
                      ? 'badge bg-success text-white'
                      : 'badge bg-danger text-white',
                  ]"
                >
                  {{ data.item.is_active == 1 ? "Active" : "Not active" }}
                </p>
              </template>
              <template #cell(action)="data">
                <b-button
                  @click="deleteAddon(data.item.id)"
                  class="bg-danger text-white"
                >
                  <b-icon icon="trash-fill" font-scale="0.9"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Pengumuman Manasik">
            <b-row>
              <b-col>
                <manasik-view></manasik-view>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <!-- modal -->
    <div class="allModal">
      <b-modal hide-footer id="perjalanan" class="custom-modal" centered title="Tambah Perjalanan" v-if="this.table.perjalanan.items.length === 0">
        <b-form @submit.prevent="addItineraries">
          <form-group-input
            ref="Deskripsi"
            type="editor"
            label="Deskripsi"
            :need-label="true"
            v-model="deskripsi"
          />
          <div class="border-top">
            <input
              type="submit"
              value="Kirim"
              class="form-control bg-warning text-white mt-3"
            />
          </div>
        </b-form>
      </b-modal>

      <b-modal
        id="perjalanan"
        class="custom-modal"
        hide-footer
        centered
        title="Edit Perjalanan"
        v-else
      >
        <b-form @submit.prevent="addItineraries">
          <form-group-input
            ref="Deskripsi"
            type="editor"
            label="Deskripsi"
            :need-label="true"
            :value="this.table.perjalanan.items[0].description"
            @input="(e) => (deskripsi = e)"
          />

          <div class="border-top">
            <input
              type="submit"
              value="Edit"
              class="form-control bg-warning text-white mt-3"
            />
          </div>
        </b-form>
      </b-modal>

      <b-modal id="airport" hide-footer centered title="Tambah Bandara">
        <b-form-group label="Bandara" label-for="Bandara" invalid-feedback="Bandara wajib di isi">
          <Treeselect v-model="modelAirport.value" :multiple="true" :options="modelAirport.options" />
        </b-form-group>
        <div class="border-top">
          <input type="submit" @click="submitAirport()" class="form-control bg-warning text-white mt-3" value="Kirim" />
        </div>
      </b-modal>

      <b-modal id="foto" hide-footer centered title="Tambah Foto">
        <b-form-group
          label="Foto"
          label-for="foto"
          invalid-feedback="foto wajib di isi"
        >
          <b-form-file
            @change="addFoto"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Masukan File..."
          ></b-form-file>
        </b-form-group>
        <div class="border-top">
          <input
            type="submit"
            @click="submitPhoto()"
            value="Kirim"
            class="form-control bg-warning text-white mt-3"
          />
        </div>
      </b-modal>

      <b-modal id="hotel" hide-footer centered title="Tambah Hotel">
        <b-form-group
          label="Hotel"
          label-for="hotel"
          invalid-feedback="hotel wajib di isi"
        >
          <Treeselect
            v-model="modelHotel.value"
            :multiple="true"
            :options="modelHotel.options"
          />
        </b-form-group>
        <div class="border-top">
          <input
            type="submit"
            @click="submitHotel()"
            class="form-control bg-warning text-white mt-3"
            value="Kirim"
          />
        </div>
      </b-modal>

      <b-modal id="airline" hide-footer centered title="Tambah Pesawat">
        <b-form-group
          label="Pesawat"
          label-for="pesawat"
          invalid-feedback="pesawat wajib di isi"
        >
          <Treeselect
            v-model="modelAirline.value"
            :multiple="true"
            :options="modelAirline.options"
          />
        </b-form-group>
        <div class="border-top">
          <input
            type="submit"
            @click="submitAirline()"
            class="form-control bg-warning text-white mt-3"
            value="Kirim"
          />
        </div>
      </b-modal>

      <b-modal id="facility" hide-footer centered title="Tambah Fasilitas">
        <b-form-group
          label="Fasilitas"
          label-for="Fasilitas"
          invalid-feedback="Fasilitas wajib di isi"
        >
          <Treeselect
            v-model="modelFacility.value"
            :multiple="true"
            :options="modelFacility.options"
          />
        </b-form-group>
        <div class="border-top">
          <input
            type="submit"
            @click="submitFacility()"
            class="form-control bg-warning text-white mt-3"
            value="Kirim"
          />
        </div>
      </b-modal>

      <b-modal id="addon" hide-footer centered title="Tambah Addon">
        <b-form-group
          label="Addon"
          label-for="Addon"
          invalid-feedback="Addon wajib di isi"
        >
          <Treeselect
            v-model="modelAddon.value"
            :multiple="true"
            :options="modelAddon.options"
          />
        </b-form-group>
        <div class="border-top">
          <input
            type="submit"
            @click="submitAddon()"
            class="form-control bg-warning text-white mt-3"
            value="Kirim"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { setModelValue, formatModelValue } from "@/_helpers";
import detailGroupInput from "@/components/DetailGroupInput.vue";
import formGroupInput from "@/components/FormGroupInput.vue";
import manasikView from "@/pages/Paket/Manasik.vue";
import Package from "@/models/Package.js";
import Hotel from "@/models/Hotel.js";
import Airlines from "@/models/Airlines.js";
import Addon from "@/models/Addon.js";
import Facility from "@/models/Facility.js";
import Manasik from "@/models/Manasik.js";
import Airport from "@/models/Airport.js";

import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const PackageModel = new Package();
const HotelModel = new Hotel();
const AirlinesModel = new Airlines();
const AddonModel = new Addon();
const FacilityModel = new Facility();
const AirportModel = new Airport();
const ManasikModel = new Manasik();

export default {
  components: {
    detailGroupInput,
    formGroupInput,
    manasikView,
    Treeselect,
  },
  watch: {
    modelFacility: {
      handler: function(val) {
        console.log(val);
      },
      deep: true,
    },
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  data() {
    return {
      itenartValue: new Array(3),
      deskripsi: "",
      time: "2022-09-05 10:10:00",
      packageDatas: "Nama Paket",
      id: "",
      data: null,
      isLoading: false,
      description: null,
      model: PackageModel.init(),
      endPoint: {
        packageHotel: process.env.VUE_APP_API_URL + "package-hotel",
        itineraries: process.env.VUE_APP_API_URL + "itineraries",
        foto: process.env.VUE_APP_API_URL + "package-photos",
        hotel: process.env.VUE_APP_API_URL + "package-hotel",
        airline: process.env.VUE_APP_API_URL + "package-airline",
        facility: process.env.VUE_APP_API_URL + "package-facility",
        addon: process.env.VUE_APP_API_URL + "package-addon",
        airport: process.env.VUE_APP_API_URL + "package-airport",
      },
      modelPhoto: null,
      modelHotel: {
        value: [],
        options: [],
      },
      modelAirline: {
        value: [],
        options: [],
      },
      modelAirport: {
        value: [],
        options: [],
      },
      modelFacility: {
        value: [],
        options: [],
      },
      modelAddon: {
        value: null,
        options: [],
      },
      modelManasik: {
        value: null,
        options: [],
      },
      showDesc: false,
      table: {
        manasik: [
          { key: "manasik_date", label: "Hari" },
          { key: "manasik_start_time", label: "Jam Mulai" },
          { key: "manasik_end_time", label: "Jam Akhir" },
          { key: "location_info", label: "Lokasi" },
          { key: "location_map", label: "Map" },
        ],
        addon: {
          fields: [
            { key: "name", label: "Nama" },
            { key: "description", label: "Deskripsi" },
            { key: "price", label: "Harga" },
            { key: "is_active", label: "Status" },
            { key: "action", label: "Aksi", tdClass: "action" },
          ],
          items: [],
        },
        hotel: {
          fields: [
            { key: "name", label: "Hotel" },
            { key: "city", label: "Kota" },
            { key: "star", label: "Renting" },
            { key: "website", label: "Situs Web" },
            { key: "google_review", label: "Ulasan" },
            { key: "is_active", label: "Status" },
            { key: "action", label: "Aksi", tdClass: "action" },
          ],
          items: [],
        },
        pesawat: {
          fields: [
            { key: "name", label: "Pesawat" },
            { key: "logo", label: "Icon" },
            { key: "description", label: "Deskripsi" },
            { key: "is_active", label: "Status" },
            { key: "action", label: "Aksi", tdClass: "action" },
          ],
          items: [],
        },
        fasilitas: {
          fields: [
            { key: "name", label: "Fasilitas" },
            { key: "logo", label: "Gambar" },
            { key: "description", label: "Deskripsi" },
            { key: "is_active", label: "Status" },
            { key: "action", label: "Aksi", tdClass: "action" },
          ],
          items: [],
        },
        perjalanan: {
          // fields: [

          //   { key: 'action', label: 'action'}
          // ],
          items: [],
        },
        foto: {
          fields: [
            { key: "path", label: "Foto" },
            {
              key: "action",
              label: "Aksi",
              tdClass: "action",
              thClass: "action",
            },
          ],
          items: [],
        },
      },
      category: "",
      city: "",
    };
  },
  mounted() {
    this.getDetail();
    this.getHotel();
    this.getAirline();
    this.getAirport();
    this.getAddon();
    this.getFacility();
  },
  methods: {
    copyPaket() {
      this.$swal
        .fire({
          title: "Anda yakin ingin copy paket ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            try {
              this.$swal.fire({
                title: "Paket Copied!",
                icon: "success",
                confirmButtonText: "Ok",
              });
            } catch (e) {
              throw e;
            }
          }
        });
    },
    formatCurrency(value) {
      return formatModelValue("price", value);
    },
    getDetail() {
      this.isLoading = true;
      PackageModel.find(this.$route.params.id)
        .then((resp) => {
          this.isLoading = false;
          setModelValue(this.model, resp);
          this.data = resp;
          this.description = this.model.description;

          this.getPhoto(resp.id);
          this.id = resp.id;
          this.table.perjalanan.items = resp.itineraries;
          this.table.pesawat.items = resp.airlines;
          this.table.hotel.items = resp.hotels;
          this.table.fasilitas.items = resp.facilities;
          this.table.addon.items = resp.addons;
          this.category = resp.category.name;
          this.city = resp.regency.name;

          // fields history input
          this.modelHotel.value = [];
          resp.hotels.forEach((data) => this.modelHotel.value.push(data.id));
          this.modelAirline.value = [];
          resp.airlines.forEach((data) =>
            this.modelAirline.value.push(data.id)
          );
          this.modelFacility.value = [];
          resp.facilities.forEach((data) =>
            this.modelFacility.value.push(data.id)
          );
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
    getHotel() {
      HotelModel.list({
        limit: 1000,
      }).then((resp) => {
        resp.data.forEach((d) => {
          this.modelHotel.options.push({ id: d.id, label: d.name });
        });
      });
    },
    getFacility() {
      FacilityModel.list({
        limit: 1000,
      }).then((resp) => {
        resp.data.forEach((d) => {
          const data = [];
          data.id = d.id;
          data.label = d.name;
          this.modelFacility.options.push(data);
        });
      });
    },
    getAirline() {
      AirlinesModel.list({
        limit: 1000,
      }).then((resp) => {
        resp.data.forEach((d) => {
          const data = [];
          data.id = d.id;
          data.label = d.name;
          this.modelAirline.options.push(data);
        });
      });
    },
    getAirport: function() {
      AirportModel.list({
        limit: 1000,
      }).then((resp) => {
        resp.data.forEach((d) => {
          const data = [];
          data.id = d.id;
          data.label = d.name;
          this.modelAirport.options.push(data);
        });
      });
    },
    getAddon() {
      AddonModel.list({
        limit: 1000,
      }).then((resp) => {
        resp.data.forEach((d) => {
          const data = [];
          data.id = d.id;
          data.label = d.name;
          this.modelAddon.options.push(data);
        });
      });
    },
    addItineraries(e) {
      const data = {
        package_id: this.id,
        title: "Hari",
        description: this.deskripsi,
        day_order: 1,
      };

      if (this.table.perjalanan.items.length == 0) {
        this.$http
          .post(this.endPoint.itineraries, data)
          .then((resp) => {
            this.isLoading = true;
            this.getDetail();
            this.$store.dispatch(
              "notification/success",
              "Data Paket berhasil ditambahkan."
            );
            this.$bvModal.hide("perjalanan");
          })
          .catch((err) => {
            this.isLoading = false;
            this.$store.dispatch("notification/error", err);
            this.$bvModal.hide("perjalanan");
          });
      } else {
        this.$http
          .patch(
            this.endPoint.itineraries + "/" + this.table.perjalanan.items[0].id,
            data
          )
          .then((resp) => {
            this.isLoading = true;
            this.getDetail();
            this.$store.dispatch(
              "notification/success",
              "Data Paket berhasil di edit."
            );
            this.$bvModal.hide("perjalanan");
          })
          .catch((err) => {
            this.isLoading = false;
            this.$store.dispatch("notification/error", err);
            this.$bvModal.hide("perjalanan");
          });
      }
    },
    deleteItineraries(id) {
      this.$http
        .delete(this.endPoint.itineraries + "/" + id)
        .then((resp) => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data berhasil di hapus"
          );
          this.$router.push("/master-data/paket/" + this.id);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
        });
    },
    getPhoto(id) {
      this.$http.get(this.endPoint.foto + "?package_id=" + id).then((resp) => {
        this.table.foto.items = [...resp.data.data];
      });
    },
    deleteFoto(id) {
      this.$http
        .delete(this.endPoint.foto + "/" + id)
        .then((resp) => {
          this.isLoading = true;
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data berhasil di hapus"
          );
          this.$router.push("/master-data/paket/" + this.id);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
        });
    },
    addFoto(e) {
      this.modelPhoto = e.target.files;
    },
    deleteHotel(id) {
      let formData = new FormData();
      formData.append("package_id", this.id);
      formData.append("hotel_id", id);
      this.$http
        .post(this.endPoint.hotel + "/detach", formData)
        .then((resp) => {
          this.$store.dispatch(
            "notification/success",
            "Data berhasil di hapus"
          );
          this.getDetail();
        })
        .catch((err) => {
          this.$store.dispatch("notification/error", err);
        });
    },
    submitAirport() {
      let airport_ids = [];

      this.modelAirport.value.forEach((data) => {
        airport_ids.push(data);
      });

      const postData = {
        package_id: this.id,
        airport_ids,
      };
      this.$http
        .post(this.endPoint.airport + "/sync", postData)
        .then(() => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data pesawat berhasil ditambahkan."
          );
          this.$bvModal.hide("airport");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("airport");
        });
    },
    submitPhoto() {
      let formData = new FormData();
      formData.append("package_id", this.id);
      formData.append("path", this.modelPhoto[0]);
      this.$http
        .post(this.endPoint.foto, formData)
        .then(() => {
          this.isLoading = false;
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data Paket berhasil ditambahkan."
          );
          this.$bvModal.hide("foto");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("foto");
        });
    },
    submitHotel() {
      let hotel_ids = [];

      console.log(this.modelHotel.value);

      this.modelHotel.value.forEach((data) => {
        hotel_ids.push(data);
      });

      const postData = {
        package_id: this.$route.params.id,
        hotel_ids: hotel_ids,
      };

      this.$http
        .post(this.endPoint.hotel + "/sync", postData)
        .then((resp) => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data hotel berhasil ditambahkan."
          );
          this.$bvModal.hide("hotel");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("hotel");
        });
    },
    deleteAirport(id) {
      console.log('masuk')
      let formData = new FormData();
      formData.append("package_id", this.id);
      formData.append("airport_id", id);

      this.$http
        .post(this.endPoint.airport + "/detach", formData)
        .then(() => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data pesawat berhasil dihapus."
          );
          this.$bvModal.hide("airport");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("airport");
        });
    },
    deleteAirline(id) {
      let formData = new FormData();
      formData.append("package_id", this.id);
      formData.append("airline_id", id);

      this.$http
        .post(this.endPoint.airline + "/detach", formData)
        .then((resp) => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data pesawat berhasil dihapus."
          );
          this.$bvModal.hide("airline");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("airline");
        });
    },
    submitAirline() {
      let airline_ids = [];

      this.modelAirline.value.forEach((data) => {
        airline_ids.push(data);
      });

      const postData = {
        package_id: this.id,
        airline_ids,
      };
      this.$http
        .post(this.endPoint.airline + "/sync", postData)
        .then((resp) => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data pesawat berhasil ditambahkan."
          );
          this.$bvModal.hide("airline");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("airline");
        });
    },
    submitFacility() {
      let facility_ids = [];

      this.modelFacility.value.forEach((data) => {
        facility_ids.push(data);
      });

      const postData = {
        package_id: this.id,
        facility_ids,
      };
      this.$http
        .post(this.endPoint.facility + "/sync", postData)
        .then(() => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data pesawat berhasil ditambahkan."
          );
          this.$bvModal.hide("facility");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("facility");
        });
    },
    deleteFacility(id) {
      let formData = new FormData();
      formData.append("package_id", this.id);
      formData.append("facility_id", id);

      this.$http
        .post(this.endPoint.facility + "/detach", formData)
        .then((resp) => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data fasilitas berhasil dihapus."
          );
        })
        .catch((err) => {
          this.$store.dispatch("notification/error", err);
        });
    },
    deleteAddon(id) {
      let formData = new FormData();
      formData.append("package_id", this.id);
      formData.append("addon_id", id);

      this.$http
        .post(this.endPoint.addon + "/detach", formData)
        .then((resp) => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data addon berhasil dihapus."
          );
        })
        .catch((err) => {
          this.$store.dispatch("notification/error", err);
        });
    },
    submitAddon() {
      let addon_ids = [];

      this.modelAddon.value.forEach((data) => {
        addon_ids.push(data);
      });

      const postData = {
        package_id: this.id,
        addon_ids,
      };
      this.$http
        .post(this.endPoint.addon + "/sync", postData)
        .then((resp) => {
          this.getDetail();
          this.$store.dispatch(
            "notification/success",
            "Data addon berhasil ditambahkan."
          );
          this.$bvModal.hide("addon");
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", err);
          this.$bvModal.hide("addon");
        });
    },
    changeSwitch(value) {
      this.$swal
        .fire({
          title: "Anda yakin?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            let data = {};
            data["is_active"] = value;
            this.$http
              .patch(PackageModel.endpoint + "/" + this.$route.params.id, data)
              .then((response) => {
                this.getDetail();
                this.$store.dispatch(
                  "notification/success",
                  "Paket Berhasil diperbaharui."
                );
              })
              .catch((error) => {
                if (process.env.NODE_ENV !== "production") {
                  console.error(error);
                }
                this.isLoading = false;
              });
          }
        });
    },
  },
};
</script>
