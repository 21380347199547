<template>
  <div>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-end mr-1 mb-3">
      <b-btn variant="primary" v-b-modal.manasik class="ml-2"
        style="width: 7em;">Atur</b-btn>
    </b-col>
    <template v-for="(m, index) in model">
      <b-col :key="index" cols="4" v-if="(typeof m.disabled === 'undefined' || !m.disabled) && m.type !== 'hidden'">
        <detail-group-input :key="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :value="m.value" :id="index">
        </detail-group-input>
      </b-col>
    </template>
    </b-row>

    <div class="allModal">
      <b-modal ref="manasik-modal" id="manasik" class="custom-modal" @shown="showModal" centered title="Tambah / Ubah Pengumuman">
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <template v-for="(m, index) in model">
                <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
              </template>
              <div>
                <b-button :href="'#/master-data/Paket'" variant="warning" class="mt-3 text-white float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
                <b-button type="submit" variant="primary" class="mt-3 float-left"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
              </div>
            </form>
        </ValidationObserver>
      </b-modal>
    </div>
  </div>

</template>

<script>
import { setModelValue, saveProcess, scrollToError, formatModelValue } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput'
import Manasik from '@/models/Manasik.js'
import detailGroupInput from '@/components/DetailGroupInput.vue'

const ManasikModel = new Manasik()

export default {
  components: {
    formGroupInput,
    detailGroupInput
  },

  data() {
    return {
      model: ManasikModel.init(),
      isLoading: false
    }
  },

  mounted() {
    this.getManasikInfo()
  },

  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },

  methods: {
  getOptions (key) {
      return typeof this.model[key].options !== 'undefined' ? objectToOptions(this.model[key].options) : null
  },
  showModal() {
    // this.$refs['manasik-modal'].show()
    Object.keys(this.model).forEach(key => {
      this.$refs[key][0].setValue(this.model[key].value)
    })

    this.$refs['package_id'][0].setValue(this.$route.params.id)
  },
  hideModal() {
    this.$refs['manasik-modal'].hide()
  },
  getManasikInfo () {
    ManasikModel.find(this.$route.params.id).then(resp => {
        setModelValue(this.model, resp)
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
      })
    },
    
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        saveProcess(this.model, this.mode === 'update').then(model => {
          this.isLoading = true
            ManasikModel.create(model).then(resp => {
              this.isLoading = false
              // this.resetForm()
              this.$store.dispatch('notification/success', 'Data Manasik berhasil disimpan.')
              this.$router.push('/master-data/Paket/' + resp.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
        }).catch(() => {})
      })
    }
  }
}
</script>